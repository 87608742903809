import 'typeface-sora'
import 'typeface-lora'

import './src/styles/global.css';

import { wrapRootElementComponent } from './config/gatsby/wrapRootElement'
import { wrapPageElementComponent } from './config/gatsby/wrapPageElement'

export const wrapRootElement = wrapRootElementComponent
export const wrapPageElement = wrapPageElementComponent
