import React, { useState } from 'react'

import addToMailchimp from 'gatsby-plugin-mailchimp'
import { isMobile } from 'react-device-detect'

import { LinkCustom } from '../LinkCustom'

interface INewsletter {
  email?: string
  message?: string
}

export const NewsletterForm: React.FC = () => {
  const [newsletter, setNewsletter] = useState<INewsletter>({
    email: '',
    message: '',
  })

  const [status, setStatus] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = e.target

    addToMailchimp(newsletter.email)
      .then((result) => {
        form.reset()
        setNewsletter({ message: result.msg })
        setStatus('SUCCESS')
      })
      .catch((result) => {
        setNewsletter({ message: result.msg })
        setStatus('ERROR')
      })
  }

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    setNewsletter({
      [name]: value,
    })
  }

  return (
    <div>
      {status.length > 0 && (
        <div className={`rounded-md mt-2 p-4 ${status === 'ERROR' ? 'bg-red-50' : 'bg-green-50'}`}>
          <div className="flex">
            <div className="flex-shrink-0">
              {status === 'ERROR' ? (
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  className="h-5 w-5 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </div>
            <div className="ml-3">
              <p
                className={`text-sm font-medium ${
                  status === 'ERROR' ? 'text-red-800' : 'text-green-800'
                }  m-0`}
              >
                {newsletter.message}
              </p>
            </div>
          </div>
        </div>
      )}

      <form className="mt-4 sm:flex sm:max-w-md" method="POST" onSubmit={handleSubmit}>
        <label htmlFor="email" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          name="email"
          id="email"
          autoComplete="email"
          required
          className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
          placeholder="Enter your email"
          value={newsletter.email}
          onChange={handleInputChange}
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            type="submit"
            className="w-full bg-primary border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
            title="Subscribe"
          >
            {isMobile ? (
              <>Subscribe</>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>
        </div>
      </form>
      <p className="mt-3 text-sm text-indigo-200">
        We care about the protection of your data. Read our{' '}
        <LinkCustom to="/privacy-policy/" className="text-white font-medium underline">
          Privacy Policy.
        </LinkCustom>
      </p>
    </div>
  )
}
