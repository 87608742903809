// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-david-dias-tsx": () => import("./../../../src/pages/about-david-dias.tsx" /* webpackChunkName: "component---src-pages-about-david-dias-tsx" */),
  "component---src-pages-be-a-guest-tsx": () => import("./../../../src/pages/be-a-guest.tsx" /* webpackChunkName: "component---src-pages-be-a-guest-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-episodes-tsx": () => import("./../../../src/pages/episodes.tsx" /* webpackChunkName: "component---src-pages-episodes-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-guests-tsx": () => import("./../../../src/pages/guests.tsx" /* webpackChunkName: "component---src-pages-guests-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-world-map-tsx": () => import("./../../../src/pages/world-map.tsx" /* webpackChunkName: "component---src-pages-world-map-tsx" */),
  "component---src-templates-episode-index-tsx": () => import("./../../../src/templates/episode/index.tsx" /* webpackChunkName: "component---src-templates-episode-index-tsx" */),
  "component---src-templates-episode-landing-index-tsx": () => import("./../../../src/templates/episodeLanding/index.tsx" /* webpackChunkName: "component---src-templates-episode-landing-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */)
}

