import React from 'react'

import { v4 as uuid } from 'uuid'

import { useSiteMetadata } from '../../hooks'
import { LinkCustom } from '../LinkCustom'
import { NewsletterForm } from '../NewsletterForm'
import { Socials } from '../Socials'

export const Footer = () => {
  const { menuLinks, copyright, siteDescription, footerLinks } = useSiteMetadata()

  return (
    <footer className="bg-gray-800 flex-shrink-0" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-none gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  About the podcast
                </h3>
                <p className="my-8 text-base text-gray-400 md:mt-8">{siteDescription}</p>
                <p className="my-8 text-sm text-gray-400 md:mt-8">
                  * people passionate about the web, professionals and hobbyists
                </p>
                <p className="my-8 text-base text-gray-400">
                  Hosted by{' '}
                  <LinkCustom to="/about-david-dias" className="underline">
                    David Dias
                  </LinkCustom>
                </p>
              </div>

              <div className="grid grid-cols-2">
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Main Menu
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {menuLinks.map((menuLink) => (
                      <li key={uuid()}>
                        <a
                          href={menuLink.link}
                          className="text-base text-gray-300 hover:text-white"
                        >
                          {menuLink.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Secondary Menu
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {footerLinks.map((link) => (
                      <li key={uuid()}>
                        <a href={link.link} className="text-base text-gray-300 hover:text-white">
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="md:grid md:grid-cols-1 md:gap-8">
            <div className="mt-12 md:mt-0">
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Subscribe to the newsletter
              </h3>
              <p className="mt-4 text-base text-gray-300">
                The latest interviews and news sent to your inbox.
              </p>
              <NewsletterForm />
            </div>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div>
            <p className="text-sm text-gray-400 md:mt-0">{copyright}</p>
            <p className="text-sm text-gray-400 md:mt-0">
              Build with{' '}
              <LinkCustom href="https://www.gatsbyjs.com/" className="underline">
                Gatsby
              </LinkCustom>
              , hosted on{' '}
              <LinkCustom href="https://www.netlify.com/" className="underline">
                Netlify
              </LinkCustom>
            </p>
          </div>
          <div className="mt-8 sm:mt-0 flex space-x-6 md:order-2">
            <Socials />
          </div>
        </div>
      </div>
    </footer>
  )
}
