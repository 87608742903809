import React from 'react'

import { Footer } from '../Footer'
import { Header } from '../Header'

export const Layout: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <main className="bg-gray-200 pb-32 flex-content bg-soundwaves bg-repeat-x bg-bottom">
        {children}
      </main>
      <Footer />
    </div>
  )
}
