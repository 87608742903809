import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { v4 as uuid } from 'uuid'

import { useSiteMetadata } from '../../hooks'
import { LinkCustom } from '../LinkCustom'
import { Platforms } from '../Platforms'
// import { Search } from '../Search'

// const searchIndices = [{ name: `Episodes`, title: `Episodes` }]

export const Header: React.FC = () => {
  const { menuLinks } = useSiteMetadata()
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    if (window.scrollY >= 20) {
      window.document.querySelector('.nav-bar').className =
        'duration-200 bg-white nav-bar shadow-md'
    } else {
      window.document.querySelector('.nav-bar').className = `duration-200 nav-bar py-5`
    }
  }

  const handleMenuState = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <header className="fixed w-full z-50">
      <div className="duration-200 nav-bar py-5">
        <nav className="mx-auto px-2 sm:px-4 lg:px-8">
          <div>
            <div className="flex justify-between h-16">
              <div className="flex px-2 lg:px-0">
                <div className="flex-shrink-0 flex items-center">
                  <LinkCustom to="/">
                    <StaticImage
                      className="block sm:hidden w-2 h-2"
                      width={30}
                      src="../../../static/assets/artwork/logo-world-web-stories.png"
                      alt="World Web Stories logo"
                      layout="fixed"
                      loading="eager"
                    />

                    <StaticImage
                      className="hidden md:block h-1 w-auto"
                      src="../../../static/assets/artwork/logo-world-web-stories-name.svg"
                      alt="World Web Stories logo"
                      layout="fixed"
                      loading="eager"
                    />
                  </LinkCustom>
                </div>
                <div className="hidden lg:ml-40 lg:ml-6 lg:flex lg:space-x-8">
                  {menuLinks.map((menuLink) => (
                    <Link
                      to={menuLink.link}
                      className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                      activeClassName="border-primary text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 font-medium"
                      key={uuid()}
                    >
                      {menuLink.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
                {/* <Search indices={searchIndices} /> */}
              </div>
              <div className="flex items-center lg:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  aria-controls="mobile-menu"
                  aria-expanded={menuOpen}
                  onClick={() => handleMenuState()}
                >
                  <span className="sr-only">Open main menu</span>

                  <svg
                    className={`${menuOpen ? 'block' : 'hidden'} h-6 w-6`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>

                  <svg
                    className={`${menuOpen ? 'hidden' : 'block'} h-6 w-6`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                <Platforms />
              </div>
            </div>
          </div>

          {menuOpen && (
            <div className="lg:hidden bg-white shadow-md" id="mobile-menu">
              <div className="pt-2 pb-3 space-y-1">
                {menuLinks.map((menuLink) => (
                  <LinkCustom
                    key={uuid()}
                    to={menuLink.link}
                    className="block pl-3 pr-4 py-2 text-base font-xl hover:border-primary hover:border-l-4"
                  >
                    {menuLink.name}
                  </LinkCustom>
                ))}
              </div>
            </div>
          )}
        </nav>
      </div>
    </header>
  )
}
