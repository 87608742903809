import { graphql, useStaticQuery } from 'gatsby'

interface IUseSiteMetadata {
  siteLanguage: string
  siteTitle: string
  siteTitleShort: string
  siteTitleAlt: string
  siteName: string
  siteLogo: string
  siteUrl: string
  siteTitleTemplate: string
  siteImage: string
  pathPrefix: string
  siteDescription: string
  analyticsID: string
  dateFromFormat: string
  dateFormat: string
  userName: string
  userEmail: string
  userTwitter: string
  userFacebook: string
  userLocation: string
  userDescription: string
  copyright: string
  themeColor: string
  backgroundColor: string
  s3bucket: string
  githubRepo: string
  imageDefault: string
  menuLinks: []
  footerLinks: []
  twitterUrl: string
  facebookUrl: string
  instagramUrl: string
  formspreeUrl: string
  recaptchaSiteKeyV2: string
  rssUrl: string
  author: {
    name: string
    email: string
  }
  organization: {
    name: string
    email: string
    logo: string
  }
}

export const useSiteMetadata = (): IUseSiteMetadata => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          siteLanguage
          siteTitle
          siteTitleShort
          siteTitleAlt
          siteName
          siteLogo
          siteUrl
          pathPrefix
          siteDescription
          siteTitleTemplate
          analyticsID
          dateFromFormat
          dateFormat
          userName
          userEmail
          userTwitter
          userFacebook
          userLocation
          userDescription
          copyright
          themeColor
          backgroundColor
          s3bucket
          githubRepo
          imageDefault
          siteImage
          author {
            name
            email
          }
          organization {
            name
            email
            logo
          }
          menuLinks {
            name
            link
          }
          footerLinks {
            name
            link
          }
          twitterUrl
          facebookUrl
          instagramUrl
          formspreeUrl
          recaptchaSiteKeyV2
          rssUrl
        }
      }
    }
  `)
  return site.siteMetadata
}
